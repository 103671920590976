import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FreeTrialSegment,
  PrepaymentHero,
  RatingSegment,
  WhatsInsideSegment,
  LibrarySegment,
} from "../../components/PageSegments/special-offer/prepayment";
import { BasicLayout } from "../../layouts/BasicLayout";
import { getStripeDiscountURL } from "../../actions/stripeAction";
import { navigate } from "gatsby";
import { BottomNavSegment } from "../../components/PageSegments";
import { getUserEmail, getUserUID, saveUserOnboardingData } from "../../services/firebase";
import {
  web_acq_prepayment_attempt,
  web_acq_prepayment_failure,
  web_acq_prepayment_seconds_spent,
  web_acq_prepayment_success,
  web_acq_prepayment_view,
} from "../../services/mixpanel/acquisition-events";
import { fbq } from "../../services/pixel";
import { useStopwatch } from "../../hooks/useStopwatch";
import {
  Subscribe_Account_AccountCreationSuccess,
  setMixpanelProperties,
} from "../../services/mixpanel/mixpanel";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT } from "../../util/variables";
import { setOneSignalAttributes } from "../../services/onesignal";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";
import { WEB_NO_TRIAL_FLOW } from "../join";
import { PrepaymentHeroNoTrial } from "../../components/PageSegments/special-offer/prepayment/PrepaymentHeroNoTrial";
import { NoTrialSegment } from "../../components/PageSegments/special-offer/prepayment/NoTrialSegment";
import { REMOTE_CONFIG_STRING_KEYS } from "../../services/firebase-remote-config";
import { LocalPriceContext } from "../../providers/LocalPriceProvider";
import { US_PRICE_WEB } from "../../util/static-price-data";
import useRemoteConfig from "../../hooks/useRemoteConfig";
import { VariantPrepaymentComponent } from "../../components/PageSegments/special-offer/VariantPrepaymentComponent";

const FREE_TRIAL_ID = "free-trial-2";
const NO_TRIAL_ID = "no-trial-2";
const NO_TRIAL_FLOW = "nt";
export const CHECKOUT_URL_KEY = "stripeCheckoutURL";
const TEST_KEY = REMOTE_CONFIG_STRING_KEYS.prePaymentVariation;

export default (data: any) => {
  const [stripeURL, setStripeURL] = useState("");
  const { currentPrice: localPrice } = useContext(LocalPriceContext);
  const [trackingComplete, setTrackingComplete] = useState(false);
  const [redirectClicked, setRedirectClicked] = useState(false);

  const { seconds } = useStopwatch();
  const { search, state } = data.location;
  const urlParams = new URLSearchParams(search);

  const useVariantParam = urlParams.get("useVariant") ?? undefined;
  const [variant, useDefault] = useRemoteConfig(TEST_KEY);
  const validVariants = ["control", "variant", "", "default"];
  // url params used for stripe cancel
  const emailParam = urlParams.get("email");
  const flowParam = urlParams.get("flow");

  const { onboardingAnswers } = useOnboardingContext();
  const { specialDiscount } = React.useContext(LocalPriceContext);

  const isNoTrial =
    (typeof window !== "undefined" &&
      sessionStorage.getItem("landing_page") === WEB_NO_TRIAL_FLOW) ||
    flowParam === NO_TRIAL_FLOW;

  const redirectToStripe = () => {
    fbq("track", "InitiateCheckout");
    triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_SUCCESS);
    web_acq_prepayment_seconds_spent(seconds);
    web_acq_prepayment_success();
    setTimeout(() => {
      document.location.href = stripeURL;
    }, 100);
  };

  const useVariant = useCallback(() => {
    if (useVariantParam) {
      return useVariantParam;
    }

    return variant;
  }, [useVariantParam, variant]);

  const redirectToPlanSelection = () => {
    navigate("/premium-plans");
  };

  const getUTMDataFromLocalStorage = () => {
    const utmData = localStorage.getItem("UTM");
    return utmData ? JSON.parse(utmData) : {};
  };

  const handleRedirect = () => {
    web_acq_prepayment_attempt();
    setRedirectClicked(true);
  };

  const forwardToNextPage = () => {
    redirectToStripe();
  };

  // Post Account creation set up 3rd party tracking and analytics
  useEffect(() => {
    const trackNewUser = async (uid: string, email: string, onboardingAnswers: object) => {
      const price = localPrice.price_id;
      const universalControlGroup = Math.floor(Math.random() * 10) + 1;
      import("../../services/braze").then(({ initialize, changeUser, getUser, openSession }) => {
        initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
        openSession();
        changeUser(uid);
        getUser()?.setEmail(email);
        getUser()?.setCustomUserAttribute("PrepaymentABPrice", price ?? "no price found");
        getUser()?.setCustomUserAttribute("customer_pool_id", universalControlGroup);
        console.log("Braze user and email has been set");
      });

      setMixpanelProperties(
        {
          AccountCreatedPlatform: "Web",
        },
        true,
      );

      localStorage.removeItem("gclid");
      setOneSignalAttributes(uid, email);
      Subscribe_Account_AccountCreationSuccess(email, uid);
      fbq("track", "CompleteRegistration");

      const storedUtm = localStorage.getItem("utmData");
      const utm = storedUtm ? JSON.parse(storedUtm) : {};
      await saveUserOnboardingData({
        uid,
        email,
        onboardingAnswers,
        userWebData: utm,
        universalControlGroup,
      });
      setTrackingComplete(true);
    };

    if (state?.uid && state?.email) {
      console.log("email", state.email);
      trackNewUser(state.uid, state.email, onboardingAnswers);
    } else {
      setTrackingComplete(true);
    }
  }, []);

  useEffect(() => {
    const getStripeURLs = async () => {
      web_acq_prepayment_view();
      triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_VIEW);

      // get Stripe Discount URL
      const emailStored = localStorage.getItem("email");
      const uidStored = localStorage.getItem("uid");
      try {
        const email = emailStored ?? state.email ?? emailParam ?? getUserEmail();
        const uid = uidStored ?? state.uid ?? getUserUID();
        const price_id = isNoTrial ? US_PRICE_WEB.price_id_no_trial : localPrice.price_id;
        const stripeURL = await getStripeDiscountURL({
          email,
          uid: uid ?? "",
          product_id: price_id,
          cancel_query_params: isNoTrial ? { flow: NO_TRIAL_FLOW } : undefined,
          // family plan and regular plan (/premium-plans), need a different stripe cancel url
          // multiple_plans: inFamilyPlanTest,
          currency_code: localPrice.currency.code,
          coupon_id: specialDiscount ? "ys0w8LBi" : undefined,
        });
        setStripeURL(stripeURL);
        sessionStorage.setItem(CHECKOUT_URL_KEY, stripeURL);
      } catch (err) {
        console.error(err);
        web_acq_prepayment_failure();
        // eslint-disable-next-line no-alert
        alert(
          "Something went wrong getting your checkout link. Please try again going through onboarding again.",
        );
        navigate("/");
      }
    };

    getStripeURLs();
  }, [emailParam, localPrice.price_id]);

  useEffect(() => {
    if (redirectClicked && Boolean(stripeURL) && trackingComplete) {
      forwardToNextPage();
    }
  }, [redirectClicked, stripeURL, seconds, trackingComplete]);

  useEffect(() => {
    if (validVariants.includes(variant as string)) {
      setMixpanelProperties(
        {
          "202411_PrepaymentTest":
            useVariant() === "control" || useVariant() === "default" ? useVariant() : variant,
        },
        true,
      );
    }
  }, [useVariant, validVariants, variant]);

  if (!validVariants.includes(variant as string) && !useDefault) {
    return null;
  }

  return (
    <BasicLayout noIndex>
      {useVariant() === "control" || useVariant() === "default" ? (
        <div>
          {isNoTrial ? (
            <PrepaymentHeroNoTrial
              priceData={localPrice}
              handleRedirect={handleRedirect}
              loading={redirectClicked}
            />
          ) : (
            <PrepaymentHero
              priceData={localPrice}
              handleRedirect={handleRedirect}
              loading={redirectClicked}
            />
          )}
          <RatingSegment scrollId={isNoTrial ? NO_TRIAL_ID : FREE_TRIAL_ID} />
          <WhatsInsideSegment scrollId={isNoTrial ? NO_TRIAL_ID : FREE_TRIAL_ID} />
          <LibrarySegment />
          {isNoTrial ? (
            <NoTrialSegment
              id={NO_TRIAL_ID}
              priceData={localPrice}
              handleRedirect={handleRedirect}
              loading={redirectClicked}
            />
          ) : (
            <FreeTrialSegment
              id={FREE_TRIAL_ID}
              priceData={localPrice}
              handleRedirect={handleRedirect}
              loading={redirectClicked}
            />
          )}
          <BottomNavSegment />
        </div>
      ) : (
        <VariantPrepaymentComponent handleRedirect={handleRedirect} loading={redirectClicked} />
      )}
    </BasicLayout>
  );
};
